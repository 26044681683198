@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  scroll-behavior: smooth;
  --tw-bg-opacity: 1;
}

#giftcardx-logo {
  transition: 200ms ease;
}

.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  padding-bottom: 10px;
  min-width: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #6f6f6f;
}
.zf-backgroundBg {
  background: #f5f5f5;
}

@font-face {
  font-family: 'Eudoxus Sans';
  font-weight: 200;
  font-display: swap;
  src: url('./assets/fonts/EudoxusSans-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Eudoxus Sans';
  font-weight: 300;
  font-display: swap;
  src: url('./assets/fonts/EudoxusSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Eudoxus Sans';
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/EudoxusSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Eudoxus Sans';
  font-weight: 500;
  font-display: swap;
  src: url('./assets/fonts/EudoxusSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Eudoxus Sans';
  font-weight: 500;
  font-display: swap;
  src: url('./assets/fonts/EudoxusSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Eudoxus Sans';
  font-weight: 700;
  font-display: swap;
  src: url('./assets/fonts/EudoxusSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Eudoxus Sans';
  font-weight: 800;
  font-display: swap;
  src: url('./assets/fonts/EudoxusSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Clash Display';
  font-weight: 700;
  font-display: swap;
  src: url('./assets/fonts/ClashDisplay-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Sora';
  font-weight: 700;
  font-display: swap;
  src: url('./assets/fonts/Sora-Bold.ttf') format('truetype');
}

/* input phone number  */

.react-tel-input .form-control {
  border: 2px solid #f1f1f1;
  padding: 0 0 0 50px;
  height: 50px;
  outline: none !important;
  box-shadow: none !important;
  transition: none !important;
}

.react-tel-input .selected-flag::before {
  opacity: 0;
}

.react-tel-input .country-list {
  margin-top: 0px;
  border: 0;
  box-shadow: none;
  border-left: 2px solid #f1f1f1;
  border-radius: 0;
  width: 299px;
  left: 1px;
  top: 44px;
  border-bottom: 2px solid #f1f1f1;
}
.react-tel-input .country-list {
  margin-top: 3px !important;
  box-shadow: none !important;
  width: 299px;
  border-radius: 0 !important;
  left: 1px !important;
  border: 0 !important;
  border-left: 2px solid #f1f1f1;
  border-right: 2px solid #f1f1f1;
  border-bottom: 2px solid #f1f1f1;
}
.react-tel-input .form-control {
  padding: 0 0 0 50px;
  /* border: 2px solid #f1f1f1; */
}
.react-tel-input .flag-dropdown.open ul.country-list {
  border: 2px solid #0040e3 !important;
  border-top: 0 !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  height: 200px !important;
}
input.form-control.open {
  border: 2px solid #0040e3;
  border-bottom: 0;
}
.react-tel-input .flag-dropdown.open ul.country-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.react-tel-input .flag-dropdown.open ul.country-list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  padding-bottom: 10px;
  min-width: 6px;
}

.react-tel-input .flag-dropdown.open ul.country-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #6f6f6f;
}
.react-tel-input .country-list .flag {
  left: 9px !important;
}
